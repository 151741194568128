import React from 'react'
import SEO from 'components/elements/SEO/SEO'
import ProductList from 'components/modules/ProductList/ProductList'
import HeroBox from 'components/modules/HeroBox/HeroBox'
import FeaturesBox from 'components/modules/FeaturesBox/FeaturesBox'
import GDPRPopup from 'components/modules/GDPRPopup/GDPRPopup'
import WorldMapSection from 'components/modules/WorldMap/WorldMapSection'

export default function HomePage() {
  return (
    <>
      <SEO title="Pagina Iniziale" keywords={['SEO0', 'SEO1', 'SEO2']} />
      <GDPRPopup />
      <HeroBox />
      <FeaturesBox />
      {/* <ProductList /> */}
      <WorldMapSection />
    </>
  )
}
