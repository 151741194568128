import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Link } from 'gatsby'
import CustomImage from 'components/foundations/Image/CustomImage'

const FeaturesBox = () => (
  <div className="closer">
    <br />
    <br />
    <br />
    <br />
    <h3 style={{ textAlign: 'center' }}>Consulenza ingegneristica avanzata per cogliere la sfida dell’innovazione</h3>
    <div className="features-grid">
      <Link to="/consulenza">
        <div className="feature-box">
          <CustomImage className="features-img" filename="diagram.png" />
          <div className="feature-content">
            <h3>Consulenza</h3>
            <Link to="/consulenza">
              <Button className="btn btn-primary">
                <Typography component="h2" variant="button">
                  Clicca qui
                </Typography>
              </Button>
            </Link>
            <h4 className="feature-subtitle">Vi coccoliamo con i nostri consigli</h4>
          </div>
        </div>
      </Link>
      <Link to="/ingegneria">
        <div className="feature-box">
          <CustomImage className="features-img" filename="factory.png" />
          <div className="feature-content">
            <h3>Ingegneria</h3>
            <Link to="/ingegneria">
              <Button className="btn btn-primary">
                <Typography component="h2" variant="button">
                  Clicca qui
                </Typography>
              </Button>
            </Link>
            <h4 className="feature-subtitle">Vi stupiremo con il rigore della follia</h4>
          </div>
        </div>
      </Link>
      <Link to="/medicina">
        <div className="feature-box">
          <CustomImage className="features-img" filename="medicsuitcase.png" />
          <div className="feature-content">
            <h3>Medicina</h3>
            <Link to="/medicina">
              <Button className="btn btn-primary">
                <Typography component="h2" variant="button">
                  Clicca qui
                </Typography>
              </Button>
            </Link>
            <h4 className="feature-subtitle">Tutto è veleno, nulla è veleno: dipende dalla dose</h4>
          </div>
        </div>
      </Link>
      <Link to="/formazione">
        <div className="feature-box">
          <CustomImage className="features-img" filename="notebook.png" />
          <div className="feature-content">
            <h3>Formazione</h3>
            <Link to="/formazione">
              <Button className="btn btn-primary">
                <Typography component="h2" variant="button">
                  Clicca qui
                </Typography>
              </Button>
            </Link>
            <h4 className="feature-subtitle">La formazione è la chiave del successo</h4>
          </div>
        </div>
      </Link>
    </div>
  </div>
)

export default FeaturesBox
