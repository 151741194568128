import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Link from 'components/foundations/Link/Link'

const HeroBox = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "info.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const background = data.background.childImageSharp.fluid

  return (
    <div className="hero-homepage">
      <Img fluid={background} className="hero-image" />
    </div>
  )
}

export default HeroBox
