import React from 'react'
import { Link } from 'gatsby'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CustomImage from 'components/foundations/Image/CustomImage'

const WorldMapSection = () => (
  <>
    <section className="closer">
      <div className="features-grid">
        <h3>La nostra presenza con CENTRI MEDICI in Italia:</h3>
        <br /> <br /> <br />
        <CustomImage filename={'italia.png'} className="maps-image" />
      </div>
      <br /> <br /> <br />
    </section>
    <section className="" style={{ width: '90vw', margin: '0 auto' }}>
      <div className="features-grid">
        <h3>La nostra presenza worldwide</h3>
        <br /> <br /> <br />
        <CustomImage filename={'map2.png'} className="maps-image2" />
      </div>
      <br /> <br /> <br />
    </section>
    <section className="closer" style={{ fontSize: '20px' }}>
      <h3 style={{ textAlign: 'justify' }}>I NOSTRI PUNTI DI FORZA: CREATIVITA’ E RIGORE, IL MIX MIGLIORE</h3>
      <p style={{ textAlign: 'justify' }}>
        Il nome ING GROUP riflette la doppia anima della società ed assume due significati diversi: la tecnica ed il
        rigore dell’ingegneria e la creatività del gruppo. <br /> <br />
        Oggi il nostro core business comprende tutte le aree della consulenza su sicurezza del lavoro e sistemi di
        gestione e tutte le attività tipiche dell’ingegnere, dalla progettazione alla direzione lavori unitamente a
        mdicina del lavoro e formazione. <br /> <br /> ING GROUP è tra le poche realtà presenti sul mercato in grado di
        soddisfare globalmente le esigenze delle aziende nel settore della progettazione e della consulenza tecnica e
        della medicina del lavoro: la nostra mission è la soddisfazione del Cliente, essere il riferimento tecnico ed il
        supporto allo sviluppo commerciale dei nostri clienti/partner. <br /> <br /> Nel mercato attuale la crescita è
        soprattutto competitività, ed il team ING GROUP sa percepire le esigenze del cliente ed affiancarlo nel
        raggiungimento degli obiettivi prefissati insieme. <br /> <br />
        Contattaci per scoprire tutti i nostri servizi.
      </p>
      <Link to="/contatti">
        <Button className="btn btn-primary">
          <Typography component="h2" variant="button">
            Contattaci
          </Typography>
        </Button>
      </Link>
      <br /> <br />
    </section>
  </>
)

export default WorldMapSection
